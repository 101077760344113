<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Карточка магазина</h5>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input label="Название" placeholder="Название" v-model="model.name" :disabled="disabled == 1">
        </base-input>
      </div>
      <div class="col-md-3">
        <label class="control-label">Интеграции</label>
        <div class="form-group">
          <div class="check-group mb-2">
            <label class="control-label">Яндекс</label>
            <base-checkbox v-model="model.yandex_enabled"  :disabled="disabled == 1">
          </base-checkbox>
          </div>
          <div class="check-group mb-2">
            <label class="control-label">Мегамаркет</label>
          <base-checkbox v-model="model.megamarket_enabled"  :disabled="disabled == 1">
          </base-checkbox>
          </div>
          <div class="check-group">
            <label class="control-label">Ozon</label>
          <base-checkbox v-model="model.ozon_enabled"  :disabled="disabled == 1">
          </base-checkbox>
          </div>
        </div>
      </div>
    </div>
    <card class="secondary-card"  v-show="model.yandex_enabled">
      <h5 slot="header" class="title">Настройки яндекс</h5>
      <div class="row">
        <div class="col-md-3">
          <base-input label="Токен" placeholder="Токен" v-model="model.yandex_settings.token"  v-if="disabled != 1">
          </base-input>
        </div>
        <div class="col-md-2">
          <base-input label="campaign_id магазина" placeholder="campaign_id" v-model="model.yandex_settings.campaign_id"  :disabled="disabled == 1">
          </base-input>
        </div>
        <div class="col-md-2">
          <base-input label="business_id магазина" placeholder="business_id" v-model="model.yandex_settings.business_id"  :disabled="disabled == 1">
          </base-input>
        </div>
        <div class="col-md-2">
          <base-input label="warehouse_id магазина" placeholder="warehouse_id" v-model="model.yandex_settings.warehouse_id"  :disabled="disabled == 1">
          </base-input>
        </div>
        <div class="col-md-1">
          <div class="form-group">
            <label class="control-label">ДБС</label>
            <base-checkbox v-model="model.yandex_settings.is_dbs"  :disabled="disabled == 1">
            </base-checkbox>
          </div>
        </div>
        <div class="col-md-2" v-if="model.yandex_settings.is_dbs">
          <base-input label="campaign_id магазина для DBS" placeholder="dbs_campaign_id" v-model="model.yandex_settings.dbs_campaign_id"  :disabled="disabled == 1">
          </base-input>
        </div>
      </div>
    </card>
    <card class="secondary-card"  v-show="model.megamarket_enabled">
      <h5 slot="header" class="title">Настройки мегамаркет</h5>
      <div class="row">
        <div class="col-md-3">
          <base-input label="Токен" placeholder="Токен" v-model="model.megamarket_settings.token"  v-if="disabled != 1">
          </base-input>
        </div>
      </div>
    </card>
    <card class="secondary-card"  v-show="model.ozon_enabled">
      <h5 slot="header" class="title">Настройки озон</h5>
      <div class="row">
        <div class="col-md-3">
          <base-input label="Токен" placeholder="Токен" v-model="model.ozon_settings.token"  v-if="disabled != 1">
          </base-input>
        </div>
        <div class="col-md-2">
          <base-input label="Client-Id магазина" placeholder="Client-Id" v-model="model.ozon_settings.client_id"  :disabled="disabled == 1">
          </base-input>
        </div>
        <div class="col-md-2">
          <base-input label="warehouse_id магазина" placeholder="warehouse_id" v-model="model.ozon_settings.warehouse_id"  :disabled="disabled == 1">
          </base-input>
        </div>
      </div>
    </card>
    <base-button slot="footer" type="primary" fill @click="save">Сохранить</base-button>
  </card>
</template>
<script>
import StoreService from '../services/store.service';
import Store from '../models/store';
import YandexSettings from '../models/yandex_settings';
import OzonSettings from '../models/ozon_settings';
import MegamarketSettings from '../models/megamarket_settings';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: new Store(null, '', false, false, false, new YandexSettings('', '', '', '', false, ''), new MegamarketSettings(''), new OzonSettings('', '', '')),
      disabled: 0
    };
  },
  mounted() {
    if (this.id !== '') {
      this.disabled = 1;
      StoreService.get(this.id).then(
        response => {
          
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
  methods: {
    save() {
      StoreService.save(this.model).then(
        response => {
          this.$router.push('/stores');
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        });
    }
  }
};
</script>
<style>
.form-check .form-check-label {
      margin-bottom: 5px;
      display: block;
}
.form-check {
    padding-left: 1.25rem;
}
.check-group {
  display: flex;
}
.secondary-card{
  box-shadow: none;
}
</style>
