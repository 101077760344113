<template>
  <div class="row">
    <div class="col-12">
      <card :title="table.title">
        <base-button slot="footer" type="primary" fill @click="createBaseProduct">Создать</base-button>
        <div class="table-responsive">
          <base-table :data="table.data" :idField="table.idField" :columns="table.columns" :actions="table.actions" :editAction="editBaseProduct"
            :deleteAction="deleteBaseProduct" thead-classes="text-primary">
          </base-table>
        </div>
      </card>
    </div>
    <modal :show.sync="baseProductModal" class="modal-base-product" id="baseProductModal" :centered="false"
      :show-close="true">
      <base-input label="SKU" placeholder="SKU" v-model="model.sku">
        </base-input>
        <base-input label="Название" placeholder="Название" v-model="model.name">
      </base-input>
      <base-input label="Цена" placeholder="Цена" v-model="model.price">
    </base-input>
      <base-button slot="footer" type="submit" fill @click="saveBaseProduct" :disabled="loading">Сохранить</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
  </div>
</template>
<script>
import BaseProductService from '../services/base_product.service';
import BaseProduct from '../models/base_product';
import Modal from '@/components/Modal';
import { BaseTable } from "@/components";

export default {
  components: {
    Modal,
    BaseTable
  },
  data() {
    return {
      baseProductModal: false,
      loading: false,
      table: {
        idField: "sku",
        title: "Базовые товары",
        columns: ["sku", "name", "price"],
        actions: ["edit", "delete"],
        data: []
      },
      model: new BaseProduct('', '', 0),
    };
  },
  mounted() {
    BaseProductService.getBoard().then(
      response => {
        this.table.data = response.data;
      },
      error => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    saveBaseProduct() {
      this.baseProductModal = false
    },
    createBaseProduct() {
      this.baseProductModal = true
    },
    deleteBaseProduct: function (id) {
      BaseProductService.delete(id).then(
        response => {
          BaseProductService.getBoard().then(
            response => {
              this.table.data = response.data;
            },
            error => {
              this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    editBaseProduct: function (sku) {
      BaseProductService.get(sku).then(
        response => {
          this.model = new BaseProduct(sku, response.data['name'], response.data['price']);
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
      this.baseProductModal = true
    }
  }
};
</script>
<style></style>
