import axios from 'axios';
import AuthService from './auth.service';
import authHeader from './auth-header';
import config from '@/config';
import store from "../store";


const instance = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });


instance.interceptors.response.use((response) => {
  return response
}, (error) => {

  if (error.response.status === 401) {
    return store.dispatch('auth/refresh').then(
      () => {
        error.config.headers = authHeader();
        error.config.baseURL = undefined;
        return instance.request(error.config);
      },
      error => {
        AuthService.logout()
      }
    );
  } else {
    return Promise.reject(error)
  }
});


class MoyskladService {
  agents(organization_id) {
    return instance.post('moysklad/agents', {'organization_id': organization_id}, { headers: authHeader() })
  }
  organizations(token) {
    return instance.post('moysklad/organizations', {'token': token}, { headers: authHeader() })
  }
  statuses(token) {
    return instance.post('moysklad/statuses', {'token': token}, { headers: authHeader() })
  }
  storages(organization_id) {
    return instance.post('moysklad/storages', {'organization_id': organization_id}, { headers: authHeader() })
  }
}

export default new MoyskladService();
