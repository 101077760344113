export default class Integration {
    constructor(id, name, token, internal_organization_id, agent, storage, additional_id, is_dbs, megamarket_auto_approve, market_type) {
      this.id = id;
      this.name = name;
      this.token = token;
      this.internal_organization_id = internal_organization_id;
      this.agent = agent;
      this.storage = storage;
      this.additional_id = additional_id;
      this.is_dbs = is_dbs;
      this.megamarket_auto_approve = megamarket_auto_approve;
      this.market_type = market_type;
    }
  }