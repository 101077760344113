import axios from 'axios';
import AuthService from './auth.service';
import authHeader from './auth-header';
import config from '@/config';
import store from "../store";


const instance = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });

instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    const originalRequest = error.config
    store.dispatch('auth/refresh').then(
      () => {
        error.config.headers = authHeader();
        error.config.baseURL = undefined;
        return instance.request(error.config);
      },
      error => {
        AuthService.logout()
      }
    );
  } else {
    return Promise.reject(error)
  }
});

class IntegrationService {
  save(integration) {
    return instance.post('integration', integration, { headers: authHeader() });
  }

  getBoard() {
    return instance.get('integrations', { headers: authHeader() });
  }

  delete(id) {
    return instance.delete('integration/' + id, { headers: authHeader() });
  }

  get(id) {
    return instance.get('integration/' + id, { headers: authHeader() });
  }
}

export default new IntegrationService();
