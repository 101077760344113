export default class StatusModel {
    constructor(new_status, approved, packed, sent, delivered, return_awaits, canceled) {
      this.new = new_status;
      this.approved = approved;
      this.packed = packed;
      this.sent = sent;
      this.delivered = delivered;
      this.return_awaits = return_awaits;
      this.canceled = canceled;
    }
  }