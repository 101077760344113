<template>
  <div class="row">
    <div class="col-12">
      <card :title="table.title">
        <base-button slot="footer" type="primary" fill @click="createUser">Создать</base-button>
        <div class="table-responsive">
          <base-table :data="table.data" :columns="table.columns" :actions="table.actions" :editAction="editUser"
            :mobileHideColumns="table.mobileHideColumns" :deleteAction="deleteUser" thead-classes="text-primary">
          </base-table>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import UserService from '../services/user.service';
import { BaseTable } from "@/components";

export default {
  components: {
    BaseTable
  },
  data() {
    return {
      table: {
        title: "Пользователи",
        columns: ["login"],
        actions: ["edit", "delete"],
        data: []
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      UserService.getBoard().then(
        response => {
          this.table.data = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    createUser() {
      this.$router.push('/user-create');
    },
    deleteUser: function (id) {
      UserService.delete(id).then(
        response => {
          this.loadData();
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    editUser: function (id) {
      this.$router.push('/user-edit/' + id);
    }
  }
};
</script>
<style></style>
