<template>
  <div class="row">
    <div class="col-12">
      <card :title="table.title">
        <base-button slot="footer" type="primary" fill @click="createVisitor">Создать</base-button>
        <div class="table-responsive">
          <base-table :data="table.data" :columns="table.columns" :actions="table.actions" :editAction="editIntegration"
            :deleteAction="deleteIntegration" :idField="table.idField"
            thead-classes="text-primary">
          </base-table>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import IntegrationService from '../services/integration.service';
import { BaseTable } from "@/components";

export default {
  components: {
    BaseTable
  },
  data() {
    return {
      table: {
        title: "Интеграции",
        columns: ["name"],
        actions: ["edit", "delete"],
        data: [],
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      IntegrationService.getBoard().then(
        response => {
          this.table.data = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    createVisitor() {
      this.$router.push('/integration-create');
    },
    deleteIntegration: function (id) {
      IntegrationService.delete(id).then(
        response => {
          this.loadData();
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    editIntegration: function (id) {
      this.$router.push('/integration-edit/' + id);
    }
  }
};
</script>
<style></style>
