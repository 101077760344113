export default class Store {
    constructor(id, name, yandex_enabled, megamarket_enabled, ozon_enabled, yandex_settings, megamarket_settings, ozon_settings) {
      this.id = id;
      this.name = name;
      this.yandex_enabled = yandex_enabled;
      this.megamarket_enabled = megamarket_enabled;
      this.ozon_enabled = ozon_enabled;
      this.yandex_settings = yandex_settings;
      this.megamarket_settings = megamarket_settings;
      this.ozon_settings = ozon_settings;
    }
  }