<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Карточка базового товара</h5>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input label="SKU" placeholder="SKU" v-model="model.sku" :disabled="disabled == 1">
        </base-input>
      </div>
      <div class="col-md-3 px-md-1">
        <base-input label="Название" placeholder="Название" v-model="model.name">
        </base-input>
      </div>
      <div class="col-md-3 px-md-1">
        <base-input label="Цена" placeholder="Цена" v-model="model.price">
        </base-input>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="save">Сохранить</base-button>
  </card>
</template>
<script>
import BaseProductService from '../services/base_product.service';
import BaseProduct from '../models/base_product';

export default {
  props: {
    sku: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: new BaseProduct('', '', 0),
      disabled: 0
    };
  },
  mounted() {
    if (this.sku !== '') {
      this.disabled = 1;
      BaseProductService.get(this.id).then(
        response => {
          this.model = new BaseProduct(this.sku, response.data['name'], response.data['price']);
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
  methods: {
    save() {
      BaseProductService.save(this.model).then(
        response => {
          this.$router.push('/base-products');
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        });
    }
  }
};
</script>
<style></style>
